<template>
<div class="my-4">
  <b-row class="mb-4">
    <b-col>
      <div class="mb-1 ml-4">
        <h4 class="mb-3"><router-link class="titleLink" :to="{ name: 'Article', params: { id: article.id}}" >{{article.title}}</router-link></h4>
        <div class="mb-2">
          <strong>Published: </strong><span>{{ moment(article.approved_at).format('YYYY-MM-DD') }}</span>
        </div>
        <div class="mb-2" v-if="article.events.length > 0">
          <strong>Events: </strong>
          <li class="ml-4" v-for="event in article.events" :key="'event' + event.id">
            <router-link :to="{ name: 'Events', params: { id: event.id}}" ><strong>{{ moment(event.date).format('YYYY-MM-DD') }}</strong> - {{event.name}}</router-link><a v-if="article.events.length > 1"></a>
          </li>
        </div>
        <div class="mb-2" v-if="article.regtopics.length > 0">
          <strong>Topics: </strong>
          <span v-for="(topic, index) in article.regtopics" :key="'topic' + topic.id">
            <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link><a v-if="index + 1 < article.regtopics.length"> | </a>
          </span>
        </div>
        <div class="mb-2" v-if="article.risks.length > 0">
          <strong>Risks: </strong><span v-for="(risk, index) in article.risks" :key="'risk' + risk.id">
            <router-link :to="{ name: 'Risk', params: { id: risk.id}}" >{{risk.name}}</router-link><a v-if="index + 1 < article.risks.length"> | </a>
          </span>
        </div>
        <div class="mb-2" v-if="article.tags.length > 0">
          <strong>Tags: </strong>
          <span v-for="(tag, index) in article.tags" :key="'tag' + tag.id">
            <router-link :to="{ name: 'NewsTag', params: { id: tag.id}}" >{{ tag.name }}</router-link><a v-if="index + 1 < article.tags.length"> | </a>
          </span>
        </div>
        <div class="mb-3" v-if="article.publications.length > 0">
          <strong>News: </strong>
          <span v-for="(publication, index) in article.publications" :key="'publication' + publication.id">
            <router-link :to="{ name: 'NewsItem', params: { id: publication.id}}" >{{publication.name}}</router-link><span v-if="index + 1 < article.publications.length"> | </span>
          </span>
        </div>
        <div v-html="article.body"></div>
      </div>
    </b-col>
  </b-row>
</div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    'propItem': {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      article: this.propItem,
      moment: moment
    }
  }
}
</script>

<style>
  .titleLink{
      text-decoration: none;
      cursor: pointer;
      color: black;
  }
</style>
